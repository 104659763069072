<template>
  <div class="add-rank-dialog">
    <page-dialog
      :isShowFooter="true"
      :width="30"
      v-if="dialogVisible"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="选择服务"
    >
      <div class="reserve-project-list">
        <div class="reserve-top-form">
          <el-select
            class="reserve-el-select"
            size="small"
            @change="handleServeTypeChange"
            v-model="optServeId"
            placeholder="选择服务分类"
          >
            <el-option
              v-for="item in teacherServeInfoList"
              :key="item.kindId"
              :label="item.kindName"
              :value="item.index"
            />
          </el-select>
          <el-input
            class="reserve-el-select"
            @input="handleSearch"
            size="small"
            prefix-icon="search"
            v-model="searchValue"
            placeholder="输入名称"
          />
        </div>
        <el-scrollbar height="260px">
          <el-radio-group @change="handleCheckChange" v-model="checkList">
            <template v-if="teacherServeList.length > 0">
              <template :key="item" v-for="item in teacherServeList">
                <el-radio :label="item.serveId">
                  <div class="item">
                    <div class="left">
                      <div class="name">{{ item.serveName }}</div>
                    </div>
                  </div>
                </el-radio>
              </template>
            </template>
            <template v-else>
              <div class="empty">
                <el-empty description="暂无服务" />
              </div>
            </template>
          </el-radio-group>
        </el-scrollbar>
      </div>
      <template #footer>
        <div class="save-btn">
          <el-button @click="handleSaveClick" size="small" type="primary">保存</el-button>
        </div>
      </template>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, nextTick, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { getShopDownList } from '@/service/main/shop'
import { useRoute } from 'vue-router'
import { getTeacherRankServeList} from '@/service/main/reserve'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    chooseList:{
      type:Array,
      default:[]
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisibleServe', 'refreshList', 'changeOptServe'],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisibleServe', flag)
    }

    const handleSaveClick = () => {
      emit('changeOptServe', checkedList.value)
      changeDialogVisible(false)
    }
    const chooseList = computed(() => props.chooseList.length?props.chooseList[0]:'')
    const optDataList = ref()
    const shopCount = ref(0)
    const optServeId = ref(0)

    const shopListInfo = ref([])
    const handleChange = value => {
      teacherServeList.value=teacherServeInfoList.value[value].infos
    }

    const checkList = ref([])

    const currentEditShop = reactive({
      id: 0,
      flag: false
    })
    watch(chooseList, (n) => {
      checkList.value = n
    }, {
      deep: true
    })

    const teacherServeInfoList = ref([])
    const teacherServeList = ref([])
    const index = ref(0)
    const initPage = async () => {
    //获取服务数据
      let data = await getTeacherRankServeList({enabled:true})
      let list = data.data
      let newList = {kindName:'所有服务',kindId:0,infos:[]}

      list.forEach((ele, index) => {
        let paralist = ele.infos
        newList.infos.push(...paralist)
      })
      let teacherServeInfoLists=[newList,...data.data]
      teacherServeInfoLists.map((item,index)=>{
        item.index=index
      })
      teacherServeInfoList.value=teacherServeInfoLists
      teacherServeList.value=teacherServeInfoList.value[0].infos
    }
    initPage()

    const handleServeTypeChange = value => {
      searchValue.value=''
      index.value=value
      teacherServeList.value=teacherServeInfoList.value[value].infos
    }


    const checkedList = ref({})
    const handleCheckChange = (value) => {
      let optNameArr = []
      let item = teacherServeList.value.find(elem => elem.serveId == value)
      if (item != undefined) {
        optNameArr.push(item.serveName)
      }
      checkedList.value={ids:[value],optNameArr}
    }

    const searchValue = ref('')
    const handleSearch = value => {
      let list = teacherServeInfoList.value[index.value].infos
      let newList = []
      if (value.trim().length > 0) {
        list.forEach(ele => {
          if (ele.serveName.indexOf(value) != -1) {

            newList.push(ele)
          }
        })
      } else {
        newList = list
      }
      teacherServeList.value = newList

    }

    return {
      handleSaveClick,
      handleChange,
      shopListInfo,
      searchValue,
      changeDialogVisible,
      optDataList,
      shopCount,
      checkList,
      handleCheckChange,
      currentEditShop,
      checkedList,
      chooseList,
      handleSearch,
      teacherServeInfoList,
      optServeId,
      handleServeTypeChange,
      index,
      teacherServeList
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;
  .reserve-project-list {

    .reserve-top-form {
      display: flex;
      justify-content: space-between;
      background-color: rgb(239, 239, 239);
      padding: 10px 20px;
      margin-bottom: 20px;

      .reserve-el-select {
        width: 45%;
      }
    }

    &:deep(.serve-list-wrap) {
      padding-right: 10 !important;
    }

    .empty {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &:deep(.el-radio) {
      display: flex;
      width: 100% !important;
      margin-right: 0;
      align-items: center;

      .el-radio__label {
        display: inline-block;
        // width: 100%;

        .item {
          width: 97%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right {
            position: relative;
            left: -8px;
          }

          .left,
          .right,
          .center {
            flex: 1;
          }

          .left {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
