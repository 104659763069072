<template>
  <div class="add-rank-dialog">
    <page-dialog
      :isShowFooter="true"
      :width="25"
      v-if="dialogVisible"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="选择门店"
    >
      <div class="shop-list">
        <div class="titls">
          <div>
            门店名称
          </div>
        </div>
        <el-scrollbar height="200px">
          <el-checkbox-group @change="handleCheckChange" v-model="checkList">
            <template :key="item" v-for="item in optDataList">
              <el-checkbox :label="item.id">
                <div class="item">
                  <div class="left">
                    <div class="name">{{ item.shopName }}</div>
                  </div>
                </div>
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </el-scrollbar>
      </div>
      <template #footer>
        <div class="save-btn">
          <el-button @click="handleSaveClick" size="small" type="primary">保存</el-button>
        </div>
      </template>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, nextTick, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { getShopDownList } from '@/service/main/shop'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    chooseList:{
      type:Array,
      default:[]
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'refreshList', 'changeOptShop'],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleSaveClick = () => {
      emit('changeOptShop', checkedList.value)
      changeDialogVisible(false)
    }

    const chooseList = computed(() => props.chooseList)
    const optDataList = ref()
    const shopCount = ref(0)

    const shopListInfo = ref([])
    const handleChange = value => {
      // console.log(value);

    }

    const checkList = ref([])

    const currentEditShop = reactive({
      id: 0,
      flag: false
    })
    watch(chooseList, (n) => {
      checkList.value = n
    }, {
      deep: true
    })


    const initPage = async () => {
      const res = await getShopDownList()
      let list = res.data
      list.unshift({
        shopId:0,
        shopName:"总部"
      })
      optDataList.value=list
    }
    initPage()


    const checkedList = ref({})
    const handleCheckChange = (value) => {
      let optNameArr = []
      value.forEach(ele => {
        let item = optDataList.value.find(elem => elem.id == ele)
        if (item != undefined) {
          optNameArr.push(item.shopName)
        }
      })
      checkedList.value={ids:value,optNameArr}
    }

    const searchValue = ref('')

    return {
      handleSaveClick,
      handleChange,
      shopListInfo,
      searchValue,
      changeDialogVisible,
      optDataList,
      shopCount,
      checkList,
      handleCheckChange,
      currentEditShop,
      checkedList,
      chooseList
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;

  &:deep(.el-scrollbar__view) {
    font-size: 12px;
  }

  &:deep(.el-checkbox__label) {
    font-size: 12px;
  }

  .save {
    &:deep(.el-button) {
      width: 100%;
    }
  }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-dialog) {
    min-width: 350px;

    .el-dialog__body {
      padding-bottom: 10px !important;
    }
  }

  &:deep(.el-dialog__body) {
    position: relative;
    top: -15px;
  }

  .search {
    margin-bottom: 14px;
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-scrollbar) {
    padding: 0 10px;
  }

  .pagination {
    margin-top: 10px;
    text-align: right;
  }

  .shop-list {
    .titls {
      padding: 0 10px;
      display: flex;
      margin-bottom: 20px;
      background-color: rgb(239, 239, 239);
      font-size: 12px;

      div {
        flex: 1;
        line-height: 40px;
      }
    }

    &:deep(.el-checkbox) {
      display: inline-block;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right {
            position: relative;
            left: -8px;
          }

          .left,
          .right,
          .center {
            flex: 1;
          }

          .left {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
